import React from "react"
import { LangContext } from "../components/globals/lang-context"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { JumboPag, Clients } from "../components/sections"
import { LABELS_PAGES_TITLES } from "../components/constants/languages"

import clientes_1 from "../images/clients/clientes_1.png"
import clientes_2 from "../images/clients/clientes_2.png"
import clientes_3 from "../images/clients/clientes_3.png"

const items = [
  {
    src: clientes_1,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    src: clientes_2,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    src: clientes_3,
    altText: "Slide 3",
    caption: "Slide 3",
  },
]

const ClientsPage = () => (
  <Layout>
    <LangContext.Consumer>
      {({ lang }) => (
        <>
          <SEO title="Clients" />
          <JumboPag
            lang={lang}
            title={LABELS_PAGES_TITLES.CLIENTS[lang]}
            items={items}
          />
          <Clients lang={lang} />
        </>
      )}
    </LangContext.Consumer>
  </Layout>
)

export default ClientsPage
